import { type Config } from "tailwindcss"

export const extendedTheme = {
	colors: {
		border: "hsl(var(--border))",
		input: {
			DEFAULT: "hsl(var(--input))",
			invalid: "hsl(var(--input-invalid))",
		},
		ring: {
			DEFAULT: "hsl(var(--ring))",
			invalid: "hsl(var(--foreground-destructive))",
		},
		background: "hsl(var(--background))",
		foreground: {
			DEFAULT: "hsl(var(--foreground))",
			destructive: "hsl(var(--foreground-destructive))",
		},
		primary: {
			DEFAULT: "hsl(var(--primary))",
			foreground: "hsl(var(--primary-foreground))",
		},
		secondary: {
			DEFAULT: "hsl(var(--secondary))",
			foreground: "hsl(var(--secondary-foreground))",
		},
		destructive: {
			DEFAULT: "hsl(var(--destructive))",
			foreground: "hsl(var(--destructive-foreground))",
		},
		muted: {
			DEFAULT: "hsl(var(--muted))",
			foreground: "hsl(var(--muted-foreground))",
		},
		accent: {
			DEFAULT: "hsl(var(--accent))",
			foreground: "hsl(var(--accent-foreground))",
		},
		popover: {
			DEFAULT: "hsl(var(--popover))",
			foreground: "hsl(var(--popover-foreground))",
		},
		card: {
			DEFAULT: "hsl(var(--card))",
			foreground: "hsl(var(--card-foreground))",
		},
	},
	borderColor: {
		DEFAULT: "hsl(var(--border))",
	},
	borderRadius: {
		lg: "var(--radius)",
		md: "calc(var(--radius) - 2px)",
		sm: "calc(var(--radius) - 4px)",
	},
	fontFamily: {
		dyslexic: ["OpenDyslexic", "sans-serif"],
	},
	fontSize: {
		// 1rem = 16px
		/** 56px size / 62px high / bold */
		mega: ["3.5rem", { lineHeight: "3.875rem", fontWeight: "700" }],
		/** 28px size / 36px high / bold */
		h1: ["1.75rem", { lineHeight: "2.25rem", fontWeight: "700" }],
		/** 24px size / 32px high / bold */
		h2: ["1.5rem", { lineHeight: "2rem", fontWeight: "700" }],
		/** 16px size / 24px high / bold */
		h3: ["1rem", { lineHeight: "1.5rem", fontWeight: "700" }],
		/** 16px size / 20px high / semi-bold */
		h4: ["1rem", { lineHeight: "1.25rem", fontWeight: "600" }],
		/** 12px size / 16px high / semi-bold */
		h5: ["0.75rem", { lineHeight: "1rem", fontWeight: "600" }],
		/** 12px size / 16px high / semi-bold */
		h6: ["0.75rem", { lineHeight: "1rem", fontWeight: "600" }],

		/** 32px size / 36px high / normal */
		"body-2xl": ["2rem", { lineHeight: "2.25rem" }],
		/** 28px size / 36px high / normal */
		"body-xl": ["1.75rem", { lineHeight: "2.25rem" }],
		/** 24px size / 32px high / normal */
		"body-lg": ["1.5rem", { lineHeight: "2rem" }],
		/** 20px size / 28px high / normal */
		"body-md": ["1.25rem", { lineHeight: "1.75rem" }],
		/** 16px size / 20px high / normal */
		"body-sm": ["1rem", { lineHeight: "1.25rem" }],
		/** 14px size / 18px high / normal */
		"body-xs": ["0.875rem", { lineHeight: "1.125rem" }],
		/** 12px size / 16px high / normal */
		"body-2xs": ["0.75rem", { lineHeight: "1rem" }],

		/** 18px size / 24px high / semibold */
		caption: ["1.125rem", { lineHeight: "1.5rem", fontWeight: "600" }],
		/** 12px size / 16px high / bold */
		button: ["0.75rem", { lineHeight: "1rem", fontWeight: "700" }],
	},
	keyframes: {
		"caret-blink": {
			"0%,70%,100%": { opacity: "1" },
			"20%,50%": { opacity: "0" },
		},
		spin: {
			"0%": { transform: "rotate(0deg)" },
			"100%": { transform: "rotate(360deg)" },
		},
	},
	animation: {
		"caret-blink": "caret-blink 1.25s ease-out infinite",
		"spin-slow": "spin 1s linear infinite",
	},
	typography: {
		DEFAULT: {
			css: {
				fontFamily: "Georgia, serif",
			},
		},
	},
} satisfies Config["theme"]
